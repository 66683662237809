import type { OfferFull, OfferMedium } from '~/types/NewTypes/CatalogNew';
import type { Offer } from '~/types/NewTypes/Profile';

export function useGtmModule() {
  const gtm = useGtm();
  const route = useRoute();

  async function pushEcommerceToDataLayer(
    eventName: string,
    items: Record<string, any>[],
    additionalFields?: Record<string, any>,
  ) {
    gtm?.push({ ecommerce: null });

    const data = additionalFields
      ? { event: eventName, ecommerce: { items, ...additionalFields } }
      : { event: eventName, ecommerce: { items } };

    gtm?.push(data);
  }

  async function sendBannerEventToDataLayer(eventName: string | 'view_promotion' | 'select_promotion', banner: any) {
    await pushEcommerceToDataLayer(eventName, [banner]);
  }

  async function sendOfferViewEventToDataLayer(eventName: string | 'view_item', offer: OfferFull) {
    await pushEcommerceToDataLayer(eventName, [
      {
        item_id: offer.id,
        item_name: offer.product_name,
        item_brand: offer.brand.name,
        item_category: offer.breadcrumbs?.at(-2)?.title,
        item_list_name: route.name,
        item_variant: offer.displayable_offer_properties?.length ? offer.displayable_offer_properties[0].value : null, // Передаем цвет товара
        price: offer.price,
      },
    ]);
  }

  async function sendOfferEventToDataLayer(
    eventName: string | 'select_item' | 'add_to_cart' | 'remove_from_cart' | 'add_to_wishlist' | 'remove_from_wishlist',
    offer: OfferMedium | OfferFull,
    index?: number,
  ) {
    await pushEcommerceToDataLayer(eventName, [
      {
        item_id: offer.id,
        item_name: offer.product_name,
        item_brand: offer.brand?.name ?? null,
        item_category: route.name === 'catalog-slug' ? route.params.slug[route.params.slug.length === 1 ? 0 : 1] : null,
        item_list_name: route.name,
        item_variant: offer.displayable_offer_properties?.length ? offer.displayable_offer_properties[0].value : null,
        price: offer.price,
        quantity: eventName === 'select_item' ? null : 1,
        index: index ? index + 1 : null,
      },
    ]);
  }

  function prepareOffersMediumForDataLayer(offers: OfferMedium[]) {
    const items = [];

    for (const offer of offers) {
      items.push({
        item_id: offer.id,
        item_name: offer.product_name,
        item_brand: offer.brand?.name ?? null,
        item_category: route.name === 'catalog-slug' ? route.params.slug[route.params.slug.length === 1 ? 0 : 1] : null,
        item_list_name: route.name,
        item_variant: offer.displayable_offer_properties?.length ? offer.displayable_offer_properties[0].value : null,
        price: offer.price,
        quantity: offer.count,
      });
    }

    return items;
  }

  async function sendPageViewOffersEventToDataLayer(
    eventName: string | 'view_item_list' | 'view_cart' | 'begin_checkout',
    offers: OfferMedium[],
  ) {
    await pushEcommerceToDataLayer(eventName, prepareOffersMediumForDataLayer(offers));
  }

  async function sendCheckoutPaymentSelectEventToDataLayer(offers: OfferMedium[], paymentType: string) {
    await pushEcommerceToDataLayer('add_payment_info', prepareOffersMediumForDataLayer(offers), {
      payment_type: paymentType,
    });
  }

  async function sendCheckoutShippingSelectEventToDataLayer(offers: OfferMedium[], shippingTier: string) {
    await pushEcommerceToDataLayer('add_shipping_info', prepareOffersMediumForDataLayer(offers), {
      shipping_tier: shippingTier,
    });
  }

  async function sendPurchaseEventToDataLayer(offers: OfferMedium[], additionalFields: Record<string, any>) {
    await pushEcommerceToDataLayer('purchase', prepareOffersMediumForDataLayer(offers), additionalFields);
  }

  function prepareOrderOffersForDataLayer(offers: Offer[]) {
    const items = [];

    for (const offer of offers) {
      items.push({
        item_id: offer.id,
        item_name: offer.product_name,
        price: offer.price,
        quantity: offer.count,
      });
    }

    return items;
  }

  async function sendPaymentInAccountEventToDataLayer(offers: Offer[]) {
    await pushEcommerceToDataLayer('payment_in_account', prepareOrderOffersForDataLayer(offers));
  }

  return {
    sendBannerEventToDataLayer,
    sendPageViewOffersEventToDataLayer,
    sendOfferViewEventToDataLayer,
    sendOfferEventToDataLayer,
    sendCheckoutPaymentSelectEventToDataLayer,
    sendCheckoutShippingSelectEventToDataLayer,
    sendPurchaseEventToDataLayer,
    sendPaymentInAccountEventToDataLayer,
  };
}
